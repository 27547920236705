import {
  adaniWilmarLogo,
  alhuaLogo,
  allegionLogo,
  avigilonLogo,
  axisBankLogo,
  axisCommunicationsLogo,
  bmwLogo,
  chitleBandhuLogo,
  claiWorldLogo,
  cocacolaLogo,
  continentalLogo,
  cpplusLogo,
  crossPointsLogo,
  delhiMetroLogo,
  deloptLogo,
  deutscheBankLogo,
  dhlLogo,
  dscLogo,
  fastSensorLogo,
  hamleysLogo,
  hikvisionLogo,
  icertisLogo,
  idfcBankLogo,
  iiflFinanceLogo,
  intelLogo,
  invueLogo,
  kochiMetroLogo,
  kpitLogo,
  mahindraFinanceLogo,
  matrixLogo,
  ncrtcLogo,
  persistentLogo,
  puneMetroLogo,
  relianceRetailLogo,
  schlageLogo,
  smartiLogo,
  southcoLogo,
  styleUnionLogo,
  tagidSolutionsLogo,
  tciLogo,
  textTronicsLogo,
  twentyFourBySevenCommandsAndControlIcon,
  vendekinLogo,
  venkeysLogo,
  rieltaLogo,
  interflexLogo,
  tbsLogo,
  spicaLogo,
  iesgLogo,
  agileLogo,
  enviroLogo,
  jioLogo,
  vamosysLogo,
  systromeLogo,
  advantechLogo,
  cerevoLogo,
  twentyFourLogo,
  fireLogo,
  eSurvelliance,
  cctvPlatformLogo,
  networkLogo,
  globalNetworkLogo,
  analyticsLogo,
  esgLogo,
  designBg,
  designIcon,
  whiteNavigationIcon,
  deploymentBg,
  deploymentIcon,
  blackNavigationIcon,
  supportBg,
  supportIcon,
  otherImage,
  sensorsImage,
  esgImage,
  monitoringImage,
  empowerIotImage,
  decadeDefenderImage,
  accessControlImage,
  empowerIotPdf,
  decadeDefenderPdf,
  accessControlPdf,
  empowerPdf,
  fortilightPdf,
  empowerImage,
  fortilightImage,
} from "../Assets";

export const companyGrowthFigures = [
  {
    id: "years-of-experience",
    title: "Years of Experience",
    count: 10,
    animation_duration: 2000,
  },
  {
    id: "pincodes-served",
    title: "Pincodes Served",
    count: 8215,
    animation_duration: 2000,
  },
  {
    id: "installation",
    title: "Installation",
    count: 14000,
    animation_duration: 4000,
  },
  {
    id: "team",
    title: "Team",
    count: 450,
    animation_duration: 2000,
  },
];

export const aboutCompanyDescription1 =
  "With an agile approach and outstanding solutions in IT that we customize for our clients, we create an unbeatable, unique experience in technology ";
export const aboutCompanyDescription2 =
  "Given our years of experience and expertise in niche areas of IT, our partnership is an invaluable asset to organizations who choose to work with us. We truly understand IT! And that is the foundation of how we are driven to deliver the unique experience that our clients usually look for.";

export const productsIntroData = [
  {
    id: "monitoring",
    title: "Monitoring",
    // description:
    //   "Temple Live Streaming | CCTV | Intrusion | Centralized Display and Signage",
    links: [
      // {
      //   id: "temple-live-streaming",
      //   title: "Temple Live Streaming",
      // },
      {
        id: "cctv",
        title: "CCTV",
      },
      {
        id: "intrusion",
        title: "Intrusion",
      },
      {
        id: "centralized-display-and-signage",
        title: "Centralized Display and Signage",
      },
    ],
  },
  {
    id: "energy-monitoring-saving",
    title: "Energy Monitoring & Saving",
    // description: "Energy Saving Solutions | BMS ",
    links: [
      {
        id: "energy-management-solutions",
        title: "Energy Management Solutions ( EMS )",
      },
      {
        id: "smart-iot",
        title: "Smart IOT",
      },
    ],
  },
  {
    id: "sensors",
    title: "Sensors",
    // description:
    //   "Addressable Fire Systems | Occupancy Monitoring |  Centralized Access Control | Access control turnstile",
    links: [
      {
        id: "addressable-fire-systems",
        title: "Addressable Fire Systems",
      },
      {
        id: "occupancy-monitoring",
        title: "Occupancy Monitoring",
      },
      // {
      //   id: "centralized-access-control",
      //   title: "Centralized Access Control",
      // },
      // {
      //   id: "access-control-turnstile",
      //   title: "Access control turnstile",
      // },
    ],
  },
  // {
  //   id: "other",
  //   title: "Other",
  //   // description: "Vending Machine | Time Attendance",
  //   links: [
  //     {
  //       id: "vending-machine",
  //       title: "Vending Machine",
  //     },
  //     {
  //       id: "time-attendance",
  //       title: "Time Attendance",
  //     },
  //   ],
  // },
];

export const platformsIntroDescription =
  "Smart Solutions for all your business needs";

export const platformsIntroData = [
  {
    id: "24x7-command-and-control",
    title: "24 x 7 Command & Control",
    description: "which includes CMS, Banking Monitoring & many more",
    icon: twentyFourLogo,
  },
  {
    id: "intrusion-and-fire-alerting",
    title: "Intrusion and Fire Alerting",
    description: "Fire Alarm, Intrusion Alarm Alert Monitoring",
    icon: fireLogo,
  },
  {
    id: "e-surveillance",
    title: "E-Surveillance",
    description:
      "Video Surveillance helps identify security incidents and take actions",
    icon: eSurvelliance,
  },
  {
    id: "cctv-based-ai-solution-and-audits",
    title: "CCTV based AI solution & audits",
    description: "Comprehensive assessment of the security measures",
    icon: cctvPlatformLogo,
  },
  // {
  //   id: "network-management",
  //   title: "Network Management",
  //   description: "Create reports with an easy to use drag-and-drop designer.",
  //   icon: networkLogo,
  // },
  // {
  //   id: "data-analysis",
  //   title: "Data Analysis",
  //   description: "Create reports with an easy to use drag-and-drop designer.",
  //   icon: analyticsLogo,
  // },
  // {
  //   id: "network-connectivity",
  //   title: "Network Connectivity",
  //   description: "Create reports with an easy to use drag-and-drop designer.",
  //   icon: globalNetworkLogo,
  // },
  // {
  //   id: "esg-solutions",
  //   title: "ESG Solutions",
  //   description: "Energy Management and saving IoT Solution",
  //   icon: esgLogo,
  // },
];

export const technologyBlockTitle =
  "Not sure where to start? We’re here to help";

export const technologyBlockDescription =
  "From quick-install solutions to tailor-made products—our team of experts can help you with everything you need";

export const pioneeringBrandsData = [
  {
    id: "metro",
    categoryTitle: "Metro",
    companies: [
      {
        company_id: "ncrtc",
        company_name: "NCRTC",
        company_logo: ncrtcLogo,
        sites_count: 24,
      },
      {
        company_id: "delhi",
        company_name: "Delhi Metro Rail Ltd",
        company_logo: delhiMetroLogo,
        sites_count: 50,
      },
      {
        company_id: "pune",
        company_name: "Pune Metro Ltd",
        company_logo: puneMetroLogo,
        sites_count: 30,
      },
      {
        company_id: "kochi",
        company_name: "Kochi Metro Rail Ltd",
        company_logo: kochiMetroLogo,
        sites_count: 25,
      },
    ],
  },
  {
    id: "it/ites",
    categoryTitle: "IT/ITES",
    companies: [
      {
        company_id: "persistent",
        company_name: "Persistent",
        company_logo: persistentLogo,
        sites_count: 15,
      },
      {
        company_id: "bmw",
        company_name: "BMW",
        company_logo: bmwLogo,
        sites_count: 0,
      },
      {
        company_id: "kpit",
        company_name: "KPIT",
        company_logo: kpitLogo,
        sites_count: 0,
      },
      {
        company_id: "continental",
        company_name: "Continental",
        company_logo: continentalLogo,
        sites_count: 0,
      },
      {
        company_id: "icertis",
        company_name: "Icertis",
        company_logo: icertisLogo,
        sites_count: 0,
      },
    ],
  },
  {
    id: "retail",
    categoryTitle: "Retail",
    companies: [
      {
        company_id: "reliance_retail",
        company_name: "Reliance Retail",
        company_logo: relianceRetailLogo,
        sites_count: 3500,
      },
      {
        company_id: "style_union",
        company_name: "Style Union Ltd",
        company_logo: styleUnionLogo,
        sites_count: 70,
      },
      {
        company_id: "hamleys",
        company_name: "Hamleys",
        company_logo: hamleysLogo,
        sites_count: 0,
      },
      {
        company_id: "chitle_bandhu",
        company_name: "Chitle Bandhu Ltd",
        company_logo: chitleBandhuLogo,
        sites_count: 10,
      },
      {
        company_id: "clai-world",
        company_name: "C'Lai World",
        company_logo: claiWorldLogo,
        sites_count: 10,
      },
      {
        company_id: "venkys",
        company_name: "Venky's",
        company_logo: venkeysLogo,
        sites_count: 30,
      },
    ],
  },
  {
    id: "warehouse",
    categoryTitle: "Warehouse",
    companies: [
      {
        company_id: "southco",
        company_name: "Southco",
        company_logo: southcoLogo,
        sites_count: 0,
      },
      {
        company_id: "adani-wilmar",
        company_name: "Adani Wilmar",
        company_logo: adaniWilmarLogo,
        sites_count: 0,
      },
      {
        company_id: "cocacola",
        company_name: "Cocacola",
        company_logo: cocacolaLogo,
        sites_count: 0,
      },
      {
        company_id: "tci",
        company_name: "TCI",
        company_logo: tciLogo,
        sites_count: 0,
      },
      {
        company_id: "dhl",
        company_name: "DHL",
        company_logo: dhlLogo,
        sites_count: 0,
      },
    ],
  },
  {
    id: "bfsi",
    categoryTitle: "BFSI",
    companies: [
      {
        company_id: "idfc_first_bank",
        company_name: "IDFC First Bank",
        company_logo: idfcBankLogo,
        sites_count: 100,
      },
      {
        company_id: "axis_bank",
        company_name: "Axis Bank",
        company_logo: axisBankLogo,
        sites_count: 1350,
      },
      {
        company_id: "mahindra_finance",
        company_name: "Mahindra Finance",
        company_logo: mahindraFinanceLogo,
        sites_count: 550,
      },
      {
        company_id: "deutsche_bank",
        company_name: "Deutsche Bank",
        company_logo: deutscheBankLogo,
        sites_count: 0,
      },
      {
        company_id: "iifl_finance",
        company_name: "IIFL Finance",
        company_logo: iiflFinanceLogo,
        sites_count: 0,
      },
    ],
  },
];

export const oemAndTechnologyPartnersData = [
  {
    id: "surveillance-products",
    categoryTitle: "Surveillance Products",
    companies: [
      {
        company_id: "hikvision",
        company_name: "Hikvision",
        company_logo: hikvisionLogo,
      },
      {
        company_id: "matrix",
        company_name: "Matrix",
        company_logo: matrixLogo,
      },
      {
        company_id: "cpplus",
        company_name: "CP Plus",
        company_logo: cpplusLogo,
      },
      {
        company_id: "alhua",
        company_name: "Alhua",
        company_logo: alhuaLogo,
      },
      {
        company_id: "axis-communications",
        company_name: "Axis Communications",
        company_logo: axisCommunicationsLogo,
      },
      {
        company_id: "avigilon",
        company_name: "Avigilon",
        company_logo: avigilonLogo,
      },
      {
        company_id: "intel",
        company_name: "Intel",
        company_logo: intelLogo,
      },
    ],
  },
  {
    id: "retails-analytics-products",
    categoryTitle: "Retails Analytics Products",
    companies: [
      {
        company_id: "cross-points",
        company_name: "Cross Points",
        company_logo: crossPointsLogo,
      },
      {
        company_id: "fast-sensor",
        company_name: "Fast Sensor",
        company_logo: fastSensorLogo,
      },
      {
        company_id: "delopt",
        company_name: "Delopt",
        company_logo: deloptLogo,
      },
      {
        company_id: "tagid-solutions",
        company_name: "Tagid Solutions",
        company_logo: tagidSolutionsLogo,
      },
      {
        company_id: "vendekin",
        company_name: "Vendekin",
        company_logo: vendekinLogo,
      },
      {
        company_id: "invue",
        company_name: "Invue",
        company_logo: invueLogo,
      },
      {
        company_id: "textronics",
        company_name: "Textronics",
        company_logo: textTronicsLogo,
      },
    ],
  },
  {
    id: "security-products",
    categoryTitle: "Security Products",
    companies: [
      {
        company_id: "smarti",
        company_name: "Smarti",
        company_logo: smartiLogo,
      },
      {
        company_id: "schlage",
        company_name: "Schlage",
        company_logo: schlageLogo,
      },
      {
        company_id: "dsc",
        company_name: "DSC",
        company_logo: dscLogo,
      },
      {
        company_id: "allegion",
        company_name: "Allegion",
        company_logo: allegionLogo,
      },
      {
        company_id: "reilta",
        company_name: "Reilta",
        company_logo: rieltaLogo,
      },
    ],
  },
  {
    id: "access-control-products",
    categoryTitle: "Access Control Products",
    companies: [
      {
        company_id: "interflex",
        company_name: "Interflex",
        company_logo: interflexLogo,
      },
      {
        company_id: "tbs",
        company_name: "TBS",
        company_logo: tbsLogo,
      },
      {
        company_id: "smarti",
        company_name: "Smarti",
        company_logo: smartiLogo,
      },
      {
        company_id: "spica",
        company_name: "Spica",
        company_logo: spicaLogo,
      },
    ],
  },
  {
    id: "energy-or-ibms-products",
    categoryTitle: "Energy or IBMS Products",
    companies: [
      {
        company_id: "iesg",
        company_name: "IESG",
        company_logo: iesgLogo,
      },
      {
        company_id: "agile",
        company_name: "Agile",
        company_logo: agileLogo,
      },
      {
        company_id: "enviro",
        company_name: "Enviro Technologies",
        company_logo: enviroLogo,
      },
    ],
  },
  {
    id: "other-products",
    categoryTitle: "Other Product Partners",
    companies: [
      {
        company_id: "jio",
        company_name: "Jio",
        company_logo: jioLogo,
      },
      {
        company_id: "vamosys",
        company_name: "Vamosys",
        company_logo: vamosysLogo,
      },
      {
        company_id: "systrome",
        company_name: "Systrome",
        company_logo: systromeLogo,
      },
      {
        company_id: "advantech",
        company_name: "Advantech",
        company_logo: advantechLogo,
      },
      {
        company_id: "cerevo",
        company_name: "Cerevo",
        company_logo: cerevoLogo,
      },
    ],
  },
];

export const caseStudiesDescription =
  "WE CREATE AN UNBEATABLE , UNIQUE EXPERIENCE IN TECHNOLOGY";

export const ourValuesArray = [
  {
    bgImage: designBg,
    icon: designIcon,
    orientation: "left",
    title: "Trust",
    description:
      "For over a decade, our company has established an exemplary standing within the industry as a reliable and reputable provider of high-quality devices and services renowned for their exceptional performance and dependability.",
    navigationIcon: whiteNavigationIcon,
  },
  {
    bgImage: deploymentBg,
    icon: deploymentIcon,
    orientation: "right",
    title: "Tailor-Made Solution",
    description:
      "We understand the immense importance your business holds for you and your team. That's why we are dedicated to providing a comprehensive range of solutions aimed at enhancing safety measures, streamlining costs, and delivering in-depth insights to drive the success of your business.",
    navigationIcon: blackNavigationIcon,
  },
  {
    bgImage: supportBg,
    icon: supportIcon,
    orientation: "left",
    title: "Support",
    description:
      "Our comprehensive support network spans across every state in India, ensuring that our clients receive assistance whenever they need it. Our dedicated customer service team is accessible round the clock, and our skilled technical experts are strategically located nationwide to swiftly deliver top-notch services.",
    navigationIcon: whiteNavigationIcon,
  },
];

export const productsIntroArray = [
  {
    id: "cctv",
    title: "Monitoring",
    image: monitoringImage,
  },
  {
    id: "energy-management-solutions",
    title: "ESG",
    image: esgImage,
  },
  {
    id: "addressable-fire-systems",
    title: "Sensors",
    image: sensorsImage,
  },
  {
    id: "energy-management-solutions",
    title: "Other",
    image: otherImage,
  },
];

export const articlesForYouArray = [
  {
    title: "Empower IOT",
    id: "empower-iot",
    image: empowerIotImage,
    pathOfPdf: empowerIotPdf,
  },
  {
    title: "A Decade of Innovation",
    id: "decade-defenders",
    image: decadeDefenderImage,
    pathOfPdf: decadeDefenderPdf,
  },
  {
    title: "Access Control",
    id: "access-control",
    image: accessControlImage,
    pathOfPdf: accessControlPdf,
  },
  {
    title: "Empower",
    id: "empower",
    image: empowerImage,
    pathOfPdf: empowerPdf,
  },
  {
    title: "Fortilight",
    id: "fortilight",
    image: fortilightImage,
    pathOfPdf: fortilightPdf,
  },
];
