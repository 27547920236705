import React from "react";
import { Heading } from "../../ReusableComponents";
import { articlesForYouArray } from "../../../Constants";
import { Link } from "react-router-dom";

//Swiper Imports
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { HomeSlide1, HomeSlide2 } from ".";

const ArticlesForYou = () => {
  return (
    <div className="flex-center w-full flex-col gap-[1rem] my-[2rem] mt-[3rem]">
      <div className="w-full flex-center flex-col flex-wrap gap-[2rem]">
        <Heading
          title={"Articles for you"}
          className={
            "text-secondary uppercase font-semibold text-[1.6rem] text-center "
          }
        />
        <Swiper
          className={`w-full flex items-center justify-center py-[1rem] h-auto `}
          grabCursor={true}
          loop={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          speed={1000}
          modules={[Autoplay, Pagination]}
          pagination={{
            clickable: true,
          }}
          style={{
            "--swiper-pagination-color": "red",
            "--swiper-pagination-bullet-size": "8px",
            "--swiper-pagination-bullet-inactive-color": "red",
            "--swiper-pagination-bullet-inactive-size": "6px",
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            400: {
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            639: {
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            768: {
              slidesPerView: 3,
              slidesPerGroup: 1,
            },
            1024: {
              slidesPerView: 3,
              slidesPerGroup: 1,
            },
            1280: {
              slidesPerView: 3,
              slidesPerGroup: 1,
            },
            1536: {
              slidesPerView: 4,
              slidesPerGroup: 1,
            },
          }}
        >
          {articlesForYouArray?.map((article, index) => (
            <SwiperSlide key={index} className="mb-[3rem]">
              <Link
                key={index}
                to={`/articles?article=${article?.id}`}
                className="flex-center flex-col gap-[1rem] hover:scale-105 duration-300 "
              >
                <div className="w-[12.5rem] h-[16rem]">
                  <img
                    src={article?.image}
                    alt=""
                    className="object-fill size-full"
                  />
                </div>
                <span className="cursor-pointer text-[0.7rem] font-semibold mt-[0.4rem] text-primary ">
                  {`Learn More`}&nbsp;&nbsp;&nbsp;{`->`}
                </span>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="flex-center flex-row flex-wrap gap-[2rem]"></div>
      </div>
    </div>
  );
};

export default ArticlesForYou;
